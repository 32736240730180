import fetch from "cross-fetch";

let nextPage = 0;

function addHours(date: Date, h: number) {
  let newHours = date.getHours() + h;
  date.setHours(newHours);
}

let timeStep = 1;
let startTime = new Date();
addHours(startTime, -timeStep);
let endTime = new Date();

function requestResources() {
  return {
    type: "REQUEST_RESOURCES",
    nextPage: nextPage,
  };
}

function receiveResources(json: any) {
  return {
    type: "RECEIVE_RESOURCES",
    resources: json,
  };
}

function requestResource(resourceId: string) {
  return {
    type: "REQUEST_RESOURCE",
    resourceId: resourceId,
  };
}

function receiveResource(resourceId: string, json: any) {
  return {
    type: "RECEIVE_RESOURCE",
    resourceId: resourceId,
    resource: json,
  };
}

function requestLatestResources() {
  return {
    type: "REQUEST_LATEST_RESOURCES",
    startTime: startTime,
    endTime: endTime,
  };
}

export function fetchResources() {
  return function (dispatch: any) {
    dispatch(requestResources());

    fetch(`/api/resource/all?page=${nextPage}&size=20`)
      .then(
        (response) => {
          nextPage++;
          return response.json();
        },
        (error) => console.log("An error occured", error)
      )
      .then((json) => dispatch(receiveResources(json)));
  };
}

export function fetchResource(resourceId: string) {
  return function (dispatch: any) {
    dispatch(requestResource(resourceId));

    fetch(`/api/resource/${resourceId}`)
      .then(
        (response) => response.json(),
        (error) => console.log("An error occured", error)
      )
      .then((json) => dispatch(receiveResource(resourceId, json)));
  };
}

export function fetchLatestResources() {
  return function (dispatch: any) {
    dispatch(requestLatestResources());

    fetch(`/api/resource/latest/${startTime.getTime()}/${endTime.getTime()}`)
      .then(
        (response) => {
          addHours(startTime, -timeStep);
          addHours(endTime, -timeStep);
          return response.json();
        },
        (error) => console.log("An error occered", error)
      )
      .then((json) => dispatch(receiveResources(json)));
  };
}
