import { connect } from "react-redux";
import ResourceList from "../components/ResourceList";
import { fetchResources } from "../actions";

interface RootState {
  resourceList: {
    resources: [];
    isFetching: boolean;
  };
}

const mapStateToProps = (state: RootState) => {
  return {
    resources: state.resourceList.resources,
    isFetching: state.resourceList.isFetching,
  };
};

const mapDispatchToProps = (dispath: any) => {
  return {
    fetchMore: () => {
      dispath(fetchResources());
    },
  };
};

const ResourceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceList);

export default ResourceListContainer;
