import { combineReducers } from "redux";
import { Resource } from "../components/ResourceList";

interface Action {
  type: string;
  resources: Array<Resource>;
  resourceId: string;
  resource: Resource;
}

const resourceList = (
  state = {
    isFetching: false,
    resources: [],
  },
  action: Action
) => {
  switch (action.type) {
    case "REQUEST_RESOURCES":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "REQUEST_LATEST_RESOURCES":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_RESOURCES":
      return Object.assign({}, state, {
        isFetching: false,
        resources: [...state.resources, ...action.resources],
      });
    default:
      return state;
  }
};

const resources = (state = {}, action: Action) => {
  switch (action.type) {
    case "RECEIVE_RESOURCES":
      return Object.assign({}, state, {
        ...state,
        ...action.resources.reduce(
          (map: { [index: string]: Resource }, resource) => {
            map[resource.id] = resource;
            return map;
          },
          {}
        ),
      });
    case "REQUEST_RESOURCE":
      return Object.assign({}, state, {
        [action.resourceId]: {
          isFetching: true,
        },
      });
    case "RECEIVE_RESOURCE":
      return Object.assign({}, state, {
        [action.resourceId]: {
          ...action.resource,
          isFetching: false,
        },
      });
    default:
      return state;
  }
};

const jsonitClient = combineReducers({
  resourceList,
  entities: combineReducers({
    resources,
  }),
});

export default jsonitClient;
