import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import LinkDuo from "./LinkDuo";

export interface Resource {
  url: string;
  contentUrl: string;
  type: string;
  id: string;
  date: string;
  src: string;
  title: string;
}

export interface Props {
  resources: Array<Resource>;
  fetchMore: () => {};
}

class ResourceList extends React.Component<any> {
  componentWillMount() {
    if (this.props.resources.length === 0) {
      this.props.fetchMore();
    }
  }

  render() {
    const typeDict: { [index: string]: Array<string> } = {
      hackernews: ["HN", "#FF8635"],
      China: ["国内", "#EBC82E"],
      International: ["国际", "#229744"],
      Finance: ["财经", "#33ceaa"],
      c2: ["c2", "#7E007b"],
      Tech: ["Tech", "#34baeb"],
      InfoQ: ["InfoQ", "#3db24b"],
    };

    return (
      <List disablePadding>
        {this.props.resources.map((resource: any) => (
          <LinkDuo
            key={resource.url}
            to={
              resource.provider === "renmin_disable"
                ? resource.contentUrl || resource.url
                : `/resource/${resource.id}`
            }
          >
            <ListItem divider>
              <ListItemText
                primary={
                  <span style={{ color: "black" }}>
                    <span
                      style={{
                        color: "white",
                        backgroundColor: typeDict[resource.type][1],
                        fontSize: "70%",
                        height: "19px",
                        lineHeight: "20px",
                        position: "relative",
                        top: "-2px",
                        marginRight: "4px",
                        borderRadius: "5px",
                        textAlign: "center",
                        padding: "0px 7px",
                      }}
                    >
                      {typeDict[resource.type][0]}
                    </span>
                    {resource.title}
                  </span>
                }
                secondary={
                  <span>
                    <Moment date={resource.date} format="YYYY-MM-DD HH:mm" />
                    <span>&nbsp;&nbsp;{resource.src}</span>
                  </span>
                }
              ></ListItemText>
            </ListItem>
          </LinkDuo>
        ))}
        <ListItem button onClick={this.props.fetchMore}>
          <ListItemText
            primary={this.props.isFetching ? "Loading..." : "Load More"}
            style={{ textAlign: "center" }}
          />
        </ListItem>
      </List>
    );
  }
}

export default ResourceList;
