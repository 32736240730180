import React from "react";
import { Link } from "react-router-dom";

function isExternalUrl(url: string): boolean {
  return /^https?:\/\//.test(url);
}

class LinkDuo extends React.Component<any> {
  render() {
    return isExternalUrl(this.props.to) ? (
      <a href={this.props.to} {...this.props} target="_blank">
        {this.props.children}
      </a>
    ) : (
      <Link to={this.props.to} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

export default LinkDuo;
