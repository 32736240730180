import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import ReactGA from "react-ga";

import "./App.css";
import ResourceListContainer from "./containers/ResourceListContainer";
import Resource from "./containers/Resource";

ReactGA.initialize("UA-45948925-4");

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  return null;
};

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
  },
});

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <AppBar position="static">
          <Toolbar>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                color="inherit"
                style={{
                  fontWeight: "bold",
                  fontSize: "120%",
                }}
              >
                Readless
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>

        <Route path="/" component={logPageView} />
        <Switch>
          <Route path="/" exact component={ResourceListContainer} />
          <Route path="/resource/:resourceId" component={Resource} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
