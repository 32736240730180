import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import HTMLReactParser from "html-react-parser";
import { fetchResource } from "../actions";
import { ScrollToTopOnMount } from "../helpers/scroll";

class RawResource extends React.Component<any> {
  componentWillMount() {
    let resourceId = this.props.match.params.resourceId;
    let resource = this.props.resources[resourceId];
    if (!resource) {
      this.props.fetchResource(resourceId);
    }
  }

  render() {
    let resource = this.props.resources[this.props.match.params.resourceId];
    if (resource && !resource.isFetching) {
      return (
        <div style={{ padding: 20 }} className="resource-container">
          <ScrollToTopOnMount />
          <Typography
            component="h2"
            style={{
              fontSize: "130%",
            }}
          >
            {resource.title}
          </Typography>
          <Typography style={{ color: "grey" }}>
            <Moment date={resource.date} format="YYYY-MM-DD HH:mm" /> &nbsp;
            {resource.src}
          </Typography>
          <div style={{ fontSize: "16px" }}>
            {HTMLReactParser(resource.content)}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    resources: state.entities.resources,
  };
};

const mapDispatchToProps = (dispath: any) => {
  return {
    fetchResource: (resourceId: string) => dispath(fetchResource(resourceId)),
  };
};

let Resource = connect(mapStateToProps, mapDispatchToProps)(RawResource);

export default Resource;
